$border: #cdcdcd;
$text: #363636;
$background: rgba(255, 255, 255, 0.8);
$button: #f4f4f4;
$button-hover: #dfdfdf;
$font-size: 10pt;
$breakpoint: 1350px;

@define-mixin reset { /* stylelint-disable-line scss/at-rule-no-unknown */
  background: transparent;
  border: 0;
  box-sizing: border-box;
  font: unset;
  color: unset;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: $font-size;
  font-weight: normal;
  margin: 0;
  padding: 0;
  outline: unset;
  text-align: left;
  vertical-align: baseline;
  line-height: 1.5em;
  text-decoration: none;
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  opacity: 1;
  width: auto;
  overflow: visible;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cznic-cookiebar {
  &-root {
    &,
    * {
      &,
      &::after,
      &::before {
        @add-mixin reset; /* stylelint-disable-line scss/at-rule-no-unknown */
      }
    }

    min-height: 48px; /* stylelint-disable-line order/order */
    display: flex;
    position: fixed;
    left: 0;
    color: $text;
    background: $background;
    overflow-y: hidden;
    padding: 4px 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    border-left: 1px solid $border;
    border-right: 1px solid $border;
    animation: appear 0.2s ease-in;
    backdrop-filter: blur(8px);
    transition: opacity 0.2s ease-in;
    z-index: 9999;

    @supports not (backdrop-filter: blur(8px)) {
      background: #fff;
    }

    @media print, speech {
      display: none;
    }

    @media screen and (max-width: $breakpoint) {
      width: 100%;
      min-width: 0;
      flex-direction: column;
      transform: none;
      left: 0;
    }

    &-position-top {
      border-bottom: 1px solid $border;
      top: 0;
      border-radius: 0 0 6px 6px;

      @media screen and (max-width: $breakpoint) {
        border-radius: 0;
      }
    }

    &-position-bottom {
      border-top: 1px solid $border;
      bottom: 0;
      border-radius: 6px 6px 0 0;

      @media screen and (max-width: $breakpoint) {
        border-radius: 0;
      }
    }
  }

  &-jump {
    display: none;
    visibility: hidden;
  }

  &-text {
    flex: 1;
    margin-right: 2em;
    line-height: 1.5em;

    @media screen and (max-width: $breakpoint) {
      margin-right: 0;
      margin-bottom: 1em;
      line-height: 1.5em;
      margin: 8px 0;
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: $breakpoint) {
      justify-content: flex-start;
      margin: 8px 0;
    }
  }

  &-button {
    padding: 0.25em 1em;
    border: 1px solid $border;
    border-radius: 4px;
    text-align: center;
    background: $button;
    height: 34px;
    line-height: 24px;
    display: inline-block;
    cursor: pointer;
    min-width: 5em;
    transition: background ease-in 0.2s;
    text-decoration: none;
    color: $text;
    font-weight: normal;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 0.75em;
    }

    &:hover,
    &:focus {
      background: $button-hover;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }
  }

  &-link-more {
    &,
    &:link,
    &:visited {
      text-decoration: underline;
      white-space: nowrap;
      color: $text;
      font-weight: normal;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $text;
      font-weight: normal;
    }
  }
}
